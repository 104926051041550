import React from "react"
import Layout from "components/layout"
import usePageData from 'hooks/queryServices.vi';
import ViewServices from 'components/ViewServices'

const ServicesPage = props => {
  const { pageContext } = props;
  const pageData = usePageData();

  return (
    <Layout pageContext={pageContext}>
      <ViewServices pageData={pageData} />
    </Layout>
  )
}
export default ServicesPage
